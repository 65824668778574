import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Layout,
  MobileProjectCarousel,
  ProjectsTable,
  SEO,
  HeroCarousel,
} from "../components"
import { ourWork } from "../content"

const ProjectTemplate = ({ data }) => {
  const {
    secondaryImage,
    tertiaryImage,
    isMobile,
    id,
    client,
    details,
    industry,
    type,
  } = data?.contentfulOurWork

  const { quote } = ourWork
  return (
    <Layout title={client}>
      <SEO title={client} />
      <HeroCarousel pageProject={data?.contentfulOurWork} />
      <div className="project-profile">
        {/** Title section */}
        <section className="dark title">
          <div className="title-container">
            <div className="six columns">
              <div className="data-group-container">
                <div className="data-group">
                  <h6 className="gray-text">Industry</h6>
                  <p className="project-data">{industry}</p>
                </div>
                <div className="data-group">
                  <h6 className="gray-text">Type</h6>
                  <p className="project-data">{type}</p>
                </div>
                <div className="data-group" />
              </div>
            </div>
            <div className="five columns offset-by-one">
              <h2>{client}</h2>
            </div>
          </div>
        </section>

        {/** Content section desktop - description and two images */}
        <section className="dark content larger-than-tablet">
          <div className="container">
            <div className="five columns offset-by-one left-column">
              {details?.details && (
                <ReactMarkdown className="details">
                  {details?.details}
                </ReactMarkdown>
              )}
              {tertiaryImage?.gatsbyImageData && (
                <GatsbyImage
                  className="tertiary-image"
                  image={tertiaryImage.gatsbyImageData}
                  alt={client}
                  imgStyle={isMobile ? { objectFit: "contain" } : null}
                />
              )}
            </div>
            <div className="six columns right-column">
              {secondaryImage?.gatsbyImageData && (
                <GatsbyImage
                  className="secondary-image"
                  alt={client}
                  image={secondaryImage.gatsbyImageData}
                  imgStyle={isMobile ? { objectFit: "contain" } : null}
                />
              )}
            </div>
          </div>
        </section>

        {/** Content section mobile - description and two images */}
        <section className="dark content mobile-and-tablet-only">
          <div className="container">
            {details?.details && (
              <ReactMarkdown className="details">
                {details?.details}
              </ReactMarkdown>
            )}
            <div className="mobile-images">
              {tertiaryImage?.gatsbyImageData && (
                <GatsbyImage
                  className="tertiary-image"
                  alt={client}
                  image={tertiaryImage.gatsbyImageData}
                  imgStyle={isMobile ? { objectFit: "contain" } : null}
                />
              )}
              {secondaryImage?.gatsbyImageData && (
                <GatsbyImage
                  className="secondary-image"
                  alt={client}
                  image={secondaryImage.gatsbyImageData}
                  imgStyle={isMobile ? { objectFit: "contain" } : null}
                />
              )}
            </div>
          </div>
        </section>

        {/** Quote section */}
        <section className="dark quote">
          <div className="container purple">
            <div className="">
              <div className="content-container">
                <p className="quotation">{quote?.text || ""}</p>
                <p>{quote?.attribution || ""}</p>
              </div>
            </div>
          </div>
        </section>

        {/** Our work section */}
        <section className="dark our-work">
          <div className="flex-column center">
            <div className="container">
              <h3 className="white-text">{`Our Work`}</h3>
              <p className="white-text">{`See what we've helped other teams accomplish:`}</p>
              <div className="flex justify-center">
                <div className="green-bar"></div>
              </div>
            </div>
          </div>
        </section>

        <div className="mobile-only">
          <MobileProjectCarousel projectToExclude={id} />
        </div>

        <div className="desktop-only">
          <ProjectsTable displayAll={false} projectToExclude={id} />
        </div>
      </div>
    </Layout>
  )
}

export default ProjectTemplate

// gets page specific images based on the page slug

export const query = graphql`
  query projectQuery($slug: String) {
    contentfulOurWork(slug: { eq: $slug }) {
      isMobile
      tertiaryImage {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
      type
      secondaryImage {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
      primaryImage {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
      id
      client
      details {
        details
      }
      industry
      isMobile
      slug
      subtitle
    }
  }
`
